// extracted by mini-css-extract-plugin
export var CarrouselWrapper2 = "KoolKoor-module--CarrouselWrapper2--oIgfA";
export var DescriptionWrapper = "KoolKoor-module--DescriptionWrapper--D2YQJ";
export var DownloaderWrapper = "KoolKoor-module--DownloaderWrapper--mS1mE";
export var EventWrapper = "KoolKoor-module--EventWrapper--L-Mx2";
export var ImageWrapper = "KoolKoor-module--ImageWrapper--4vnKs";
export var ImagesWrapper = "KoolKoor-module--ImagesWrapper--TiVzv";
export var MobileTile = "KoolKoor-module--MobileTile--d0-Qz";
export var PdpWrapper = "KoolKoor-module--PdpWrapper--y3Pbh";
export var PhotosWrapper = "KoolKoor-module--PhotosWrapper--vX8PH";
export var TitleWrapper = "KoolKoor-module--TitleWrapper--gyx7H";
export var Wrapper = "KoolKoor-module--Wrapper--wGPBQ";