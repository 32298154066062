import * as React from "react";
import {
  Wrapper,
  EventWrapper,
  PdpWrapper,
  DescriptionWrapper,
  DownloaderWrapper,
  PhotosWrapper,
CarrouselWrapper2,
  TitleWrapper,
  ImageWrapper,
  MobileTile
} from "./KoolKoor.module.css";

import { GrayTitle } from "./../../../../css/Title.module.css";
import KoolKoor0 from "./../../../../res/KoolKoor/expo/kool koor0.jpg";
import KoolKoor1 from "./../../../../res/KoolKoor/expo/kool koor1.jpg";
import KoolKoor2 from "./../../../../res/KoolKoor/expo/kool koor2.jpg";
import KoolKoor3 from "./../../../../res/KoolKoor/expo/kool koor3.jpg";
import KoolKoor4 from "./../../../../res/KoolKoor/expo/kool koor4.jpg";
import KoolKoor5 from "./../../../../res/KoolKoor/expo/kool koor5.jpg";
import KoolKoor6 from "./../../../../res/KoolKoor/expo/kool koor6.jpg";
import KoolKoor7 from "./../../../../res/KoolKoor/expo/kool koor7.jpg";
import KoolKoor8 from "./../../../../res/KoolKoor/expo/kool koor8.jpg";
import KoolKoor9 from "./../../../../res/KoolKoor/expo/kool koor9.jpg";
import KoolKoor10 from "./../../../../res/KoolKoor/expo/kool koor10.jpg";
import KoolKoor11 from "./../../../../res/KoolKoor/expo/kool koor11.jpg";
import PdfKoolKoor from "../../../../res/KoolKoor/PresseKoolKoor.pdf"
import Carrousel from "../../../../components/Carrousel";
import Title from "../../../../components/Title";
import Tile from "./../../../../res/KoolKoor/expo/koolkoor-tile.png"
import Carousel from 'react-multi-carousel';

const isBrowser = typeof window !== "undefined";
const infos ={
  photos: [
    { src: KoolKoor1, name: "KoolKoor" },
    { src: KoolKoor4, name: "KoolKoor" },
    { src: KoolKoor5, name: "KoolKoor" },
  ],
};

const KoolKoor = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={EventWrapper}>
        <img className={PdpWrapper} src={Tile} alt="koolkoor"/>
        <div className={DescriptionWrapper}>
        <h1 className={TitleWrapper}>Kool Koor<h2 style={{paddingLeft: "16px"}}>Rooks & Robots - Marseille - 2021</h2></h1>
        <br />
        <img className={MobileTile} src={Tile} alt="koolkoor"/>
        <br/>
        <p>
        Marseille, June 28, 2021. The Château de Forbin and the GHOST GALERIE Marseille, two contemporary art venues in Marseille dedicated to graffiti and Post-graffiti of the 80s, will host from July 4 to August 4, 2021 the first double exhibition of New York artist Kool Koor. This event is a first for the Brussels-based artist. Named "Rooks & Robots" in homage to his artist's name, this double exhibition to be discovered soon is a return to the origins of the artist as well as to the universe of his very first works, a very galactic era on the theme of Rooks & Robots.   </p>
          <br></br>
          <br></br>
          <a className={DownloaderWrapper} href={PdfKoolKoor}download rel="noopener noreferrer" target="_blank">
              Télécharger le communiqué de presse
          </a>
          <br />
        </div>
      </div>
        
        <div className={PhotosWrapper}>
          <Title title={"Vues in situ"} />
          <div className={CarrouselWrapper2}>
          <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
          </div>
        </div>
        <br />

      {/* <div className={InfosWrapper}>
        <div className={DetailsWrapper}>
          <p className={SectionTitle}>Détails</p>
          <div className={LineWrapper}></div>
          <div className={RowWrapper}>
            <p>Date:</p>
            <div className={Spacer}></div>
            <p>Avril 2018</p>
          </div>
          <div className={PaddingTop10}></div>
          <div className={RowWrapper}>
            <p>Event category:</p>
            <div className={Spacer}></div>
            <p>Exhibitions</p>
          </div>
        </div>
        <div className={Spacer}></div>
        <div className={OrganisationWrapper}>
          <p className={SectionTitle}>Organisation</p>
          <div className={LineWrapper}></div>
          <div className={RowWrapper}>
            <div className={Spacer}></div>
            <p>Ghost Galerie</p>
          </div>
          <div className={PaddingTop10}></div>
          <div className={RowWrapper}>
            <p>Téléphone:</p>
            <div className={Spacer}></div>
            <a className={LinkWrapper} href="tel:+33633131465">
              +33(0)6.33.13.14.65
            </a>
          </div>
          <div className={PaddingTop10}></div>
          <div className={RowWrapper}>
            <p>Email:</p>
            <div className={Spacer}></div>
            <a className={LinkWrapper} href="mailto:contact@ghostgalerie.com">
              contact@ghostgalerie.com
            </a>
          </div>
        </div>
        <div className={Spacer}></div>
        <div className={AddressWrapper}>
          <p className={SectionTitle}>Adresse</p>
          <div className={LineWrapper}></div>
          <div className={RowWrapper}>
            <div className={Spacer}></div>
            <p>2 rue de Bélloi, 13006 Marseille</p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default KoolKoor;